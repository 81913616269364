import React from 'react';
import './PartnerLink.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedin, faGoogle} from '@fortawesome/free-brands-svg-icons';

const PartnerLink = (props) => {
    return (
        <div>
            <div className="partner-icon-container">
                {
                    (props.name == 'Facebook') && ( 
                        <a href={props.link} target="_blank">
                            <FontAwesomeIcon icon={faFacebook} className={'partner-icon '+props.name+'-icon'} />
                        </a>
                        )
                }       
                
                {                    
                    (props.name == 'Linkedin') && (
                        <a href={props.link} target="_blank">
                            <FontAwesomeIcon icon={faLinkedin} className={'partner-icon '+props.name+'-icon'} />
                        </a>
                    )
                }

{                    
                    (props.name == 'Google') && (
                        <a href={props.link} target="_blank">
                            {/* <FontAwesomeIcon icon={faGoogle} className={'partner-icon '+props.name+'-icon'} /> */}
                            <img className="partner-icon google-logo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/480px-Google_%22G%22_logo.svg.png" alt="Google MyBusiness"/>
                        </a>
                    )
                }
                
            </div>
            <div className="partner-icon-label-container">
                {props.label}
            </div>
        </div>
    )
}

export default PartnerLink

import React from 'react'
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons';
import './Footer.css';
import PartnerLink from '../../components/PartnerLink/PartnerLink';

const Footer = () => {

const GET_FOOTER = gql`{
    footer{	
        id
        CompanyName
        Address
        ABN
        Email
        Phone1
        Phone2
        Facebook
        Instagram
        Twitter
        Linkedin
        Google
    }
}
`;

const { loading, error, data } = useQuery(GET_FOOTER);
if (loading) return (
  "Loading.."
  )
if (error) return `Error! ${error.message}`;

    return (
        <div className="footer-wrapper">
            <div className="footer-container">
                <div className="footer-data company-name">© 2020 - 2022 {data.footer.CompanyName}</div>
                <div className="footer-data other-data">
                    <FontAwesomeIcon icon={faPhone}/> {data.footer.Phone1 + ' | ' + data.footer.Phone2 + ' | '} 
                    
                    <FontAwesomeIcon icon={faEnvelope}/> {data.footer.Email}
                </div>
                <div className="footer-data other-data"><FontAwesomeIcon icon={faMapMarkerAlt}/> <a href={'http://maps.google.com/maps?q=' + data.footer.Address} target="_blank">{data.footer.Address}</a></div>
                <div className="footer-data other-data">{'ABN ' + data.footer.ABN}</div>
                {/* <div className="footer-data other-data"><a href={data.footer.Facebook} target="new">Follow us on Facebook</a></div> */}
            </div>
            <PartnerLink name="Facebook" label="Like/Follow us" link={data.footer.Facebook}/>
            <PartnerLink name="Linkedin" label="Connect with us" link={data.footer.Linkedin}/>
            <PartnerLink name="Google" label="Find us" link={data.footer.Google}/>
        </div>
        
    )
}

export default Footer;

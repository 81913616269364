import React from "react";
import Slider from "react-slick";
import useWindowSize from '../../hooks/useWindowSize'
import '../../components/Banner/Banner.css';


const BannerCarousel = (props) => {      
  const size = useWindowSize();
  var banner_carousel_container = "banner-carousel-container";

  if (size.width < 1920) {
    banner_carousel_container  = "banner-carousel-container-small";
  } else {
    banner_carousel_container  = "banner-carousel-container";
  }

  var settings = {
    dots: true,
    infinite: true,
    speed: 10000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  }; 

  return (  
    <Slider {...settings}>
      <div>
        <img
            className={banner_carousel_container}
            src={process.env.REACT_APP_BACKEND_URL + '/homepage/banner/01.png'}
            alt={"ADVERT 01"}
        />
      </div> 
      <div>
        <img
            className={banner_carousel_container}
            src={process.env.REACT_APP_BACKEND_URL + '/homepage/banner/02.png'}
            alt={"ADVERT 02"}
        />
      </div>
      <div>
        <img
            className={banner_carousel_container}
            src={process.env.REACT_APP_BACKEND_URL + '/homepage/banner/03.png'}
            alt={"ADVERT 03"}
        />
      </div>    
    </Slider>
  );
}

export default BannerCarousel;

import React from 'react';
import Card from 'react-bootstrap/Card';
import './ProductFilter.css';
import Button from 'react-bootstrap/Button'
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faSearch } from '@fortawesome/free-solid-svg-icons';

const PriceFilter = (props) => {
    return (
        <div className="apc-categories-container">
            <Card>
                <Card.Header className="apc-categories-header">Pricing</Card.Header>
                <Card.Text>
                    <div className="apc-textbox-container">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">min</span>
                            </div>
                            {
                               (props.minprice != 0) && <input type="number" name="search" className="form-control apc-textbox" defaultValue={props.minprice}  onChange={props.minpricing}/> 
                            }
                            {
                               (props.minprice == 0) && <input type="number" name="search" className="form-control apc-textbox" placeholder="1000.00"  onChange={props.minpricing}/> 
                            }
                        </div>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">max</span>
                            </div>
                            {
                               (props.minprice != 0) && <input type="number" name="search" className="form-control apc-textbox" defaultValue={props.maxprice} pattern="[0-9]{*}" onChange={props.maxpricing}/> 
                            }
                            {
                               (props.minprice == 0) && <input type="number" name="search" className="form-control apc-textbox" placeholder="1000.00" pattern="[0-9]{*}" onChange={props.maxpricing}/> 
                            }
                        </div>
                    </div>
                    <div className="apc-textbox-container">
                        <Button variant="primary" onClick={props.pricingclick}>Go</Button>
                    </div>
                </Card.Text>    
            </Card>
        </div>
    );
}

export default PriceFilter;
import React, { useState } from "react";
//import Carousel from 'react-bootstrap/Carousel';
//import Carousel, { Dots } from '@brainhubeu/react-carousel';
//import '@brainhubeu/react-carousel/lib/style.css';
import Slider from "react-slick";
import './Product.css';


const ProductDetailsCarousel = (props) => {      
  var settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  }; 

  return (  
    <Slider {...settings}>
        {
        props.Photos.map(item =>  
          <div>
            <img
              className="product-carousel-image"
              src={process.env.REACT_APP_BACKEND_URL + item.url}
              alt={props.ProductName}
            />
          </div>
        )  
        }
      </Slider>
  );
}

export default ProductDetailsCarousel;

import React from "react";
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import './Toolbar.css';
import Nav from 'react-bootstrap/Nav';
import {Link} from "react-router-dom";

const RenderNavBarLinks = () => {
    const GET_LINKBARS = gql`{
        mainBars(where: {Active: true, WithSub: false}) {
          id
          AppOrder
          Name
          Link
          Description
          WithSub
        }
      }
      `;

    const { loading, error, data} = useQuery(GET_LINKBARS);
    if (loading) return 'Loading...';
    if (error) return `Error! ${error.message}`;
    
    
    return (
      data.mainBars.map(bar =>  
        <Nav.Item>
          <Nav.Link as={Link} to={bar.Link} className="custom-underline">{bar.Name}</Nav.Link>
        </Nav.Item>
      )
    );
}

export default RenderNavBarLinks;
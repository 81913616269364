import React from 'react'
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card';
import './MenuAccordion.css';
import {Link} from "react-router-dom";
//import 'bootstrap/dist/css/bootstrap.css';

function MenuAccordion() {
    const GET_DROPDOWNBARS = gql`{
        mainBars(where: {Active: true, WithSub: true}) {
          id
          AppOrder
          Name
          Link
          Description
          WithSub
          sub_bars{
            id
            Name
            Link
            AppOrder
          }
        }
      }
    `;

    const { loading, error, data } = useQuery(GET_DROPDOWNBARS);
    if (loading) return 'Loading...';
    if (error) return `Error! ${error.message}`;

    return (
        
        //<div className="side_drawer">
            <Accordion>
            {data.mainBars.map(bar=>(
                
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey={bar.id}>
                    {bar.Name}
                    </Accordion.Toggle>
                    {
                        bar.sub_bars.map(item=>(
                        <Accordion.Collapse eventKey={bar.id}>
                            <Card.Text >
                                <div className="apc-link-container">
                                    <a className="apc-link" href={item.Link}>{item.Name}</a>
                                </div>
                            </Card.Text>
                        </Accordion.Collapse>
                        ))
                    }
                </Card>
                
            ))}
            </Accordion>   
        //</div>
    )
}

export default MenuAccordion;

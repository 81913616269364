import React from 'react';
import './AboutUs.css';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs} from '@fortawesome/free-solid-svg-icons';
import ReactGA from 'react-ga';

const AboutUs = () => {
    ReactGA.initialize('UA-174498279-1');
    ReactGA.pageview('About Us');

    return (   
      <div>
          <div className="aboutus-content-wrapper">
              <section className="section is-medium">
                <h2 className="aboutus-header-text">
                    Powering the Australian Home, Business, and Lifestyle
                </h2>
                <p className="aboutus-content-text">
                    At Australian Power Corporation we pride ourselves on exceptional customer service, over 35 combined years of industry experience and delivering quality products at an excellent price. 
                </p>
                <p className="aboutus-content-text">
                    Located in Rocklea, Brisbane however no job is too big or small with our domestic and international connections to find the products you need at the right price! 
                </p>
               
                <div>
                    <Card className="specialise-card" style={{ width: '58rem',}}>
                        <Card.Header><span className="aboutus-content-text emphasize">We specialise in the following</span></Card.Header>
                        <Card.Body>
                            <div className="aboutus-2column">
                                <div>
                                    <ListGroup variant="flush">
                                        <ListGroup.Item className="aboutus-specialise-text"><FontAwesomeIcon icon={faCogs}/> New & Used Diesel Generators (3- 30,000kVA)</ListGroup.Item>
                                        <ListGroup.Item className="aboutus-specialise-text"><FontAwesomeIcon icon={faCogs}/> Portable Generators</ListGroup.Item>
                                        <ListGroup.Item className="aboutus-specialise-text"><FontAwesomeIcon icon={faCogs}/> Compressors</ListGroup.Item>
                                        <ListGroup.Item className="aboutus-specialise-text"><FontAwesomeIcon icon={faCogs}/> Machinery</ListGroup.Item>
                                        <ListGroup.Item className="aboutus-specialise-text"><FontAwesomeIcon icon={faCogs}/> Power Stations</ListGroup.Item>
                                        <ListGroup.Item className="aboutus-specialise-text"><FontAwesomeIcon icon={faCogs}/> Transformers</ListGroup.Item>
                                        <ListGroup.Item className="aboutus-specialise-text"><FontAwesomeIcon icon={faCogs}/> Fuel Cells</ListGroup.Item>
                                        <ListGroup.Item className="aboutus-specialise-text"><FontAwesomeIcon icon={faCogs}/> Distribution Boards</ListGroup.Item>
                                        <ListGroup.Item className="aboutus-specialise-text"><FontAwesomeIcon icon={faCogs}/> 24/7 Engineering Services</ListGroup.Item>
                                        <ListGroup.Item className="aboutus-specialise-text"><FontAwesomeIcon icon={faCogs}/> Contract maintenance</ListGroup.Item>
                                    </ListGroup>
                                </div>
                                <div>
                                    <img className="apc-logo-spec" src={process.env.REACT_APP_BACKEND_URL + "/logo/apc-gray-spinner.png"} alt="Australian Power Corporation Logo"/>
                                </div>
                            </div>
                        </Card.Body>
                        <Card.Footer>
                            <span className="aboutus-content-text footer">We also offer installation, servicing & customised power products</span>
                        </Card.Footer>
                    </Card>
                </div>   
              </section>
              <section className="section is-medium ">
                <h2 className="aboutus-header-text">
                    Over 35 Years in the Generator Industry
                </h2>
                <p className="aboutus-content-text">
                    On March 1986, <b>Generator Sales & Services</b> was established with Steve Clements as Managing Director and Founder.
                </p>
                <p className="aboutus-content-text">
                    On January 1989, <b>Generators Qld</b> was also established with Steve Clements as Managing Director and Founder.
                </p>
                <p className="aboutus-content-text">
                Over the years, both companies developed very strong and wide domestic and international connections. 
                With excellent service, impeccable product quality, and solid client and partners relation, these companies 
                quickly rose up to become leaders in Diesel Generators and Power Products. 
                </p>
                <div className="aboutus-img-container">
                    <div className="history-pics-01">
                        <img className="aboutus-img" src={process.env.REACT_APP_BACKEND_URL + "/history/1986-02.jpg"} alt="1986 Generator Sales & Services" />
                    </div>
                    <div className="history-pics-02">
                        <img className="aboutus-img" src={process.env.REACT_APP_BACKEND_URL + "/history/1986-01.jpg"} alt="1986 Generator Sales & Services" />
                    </div>
                    <span className="img-comment">
                        <b>1986 - Scrubby Creek Clay Target Club</b> <br />
                        Generator Sales & Service was the major sponsor of this annual event from 1986 - 1988.
                    </span>
                    <div className="history-pics-05">
                        <img className="aboutus-img" src={process.env.REACT_APP_BACKEND_URL + "/history/1989-01.jpg"} alt="1989 Generators Qld" />
                    </div>
                    <div className="history-pics-03">
                        <img className="aboutus-img-wide" src={process.env.REACT_APP_BACKEND_URL + "/history/1989-02.jpg"} alt="1989 Generators Qld" />
                    </div>
                    <div className="history-pics-04">
                        <img className="aboutus-img-wide" src={process.env.REACT_APP_BACKEND_URL + "/history/1989-03.jpg"} alt="1989 Generators Qld" />
                    </div>
                    <br />
                    <span className="img-comment">
                        <b>1989 - Gunpowder, Qld Copper Mine Project</b> <br />
                        Terry Clements and Ian Bailey (2-Man Team) of Generators Qld conducted on-site installation of 13 Generators within 9 days.
                    </span>
                </div>
              </section>
              <section className="section is-medium ">
                <h2 className="aboutus-header-text">
                    A Family-Owned Australian Business
                </h2>
                <div className="history-pics-06">
                    <img className="aboutus-img-wider" src={process.env.REACT_APP_BACKEND_URL + "/history/2020-01.jpg"} alt="2020 Australian Power Corporation" />
                </div>
                <p className="aboutus-content-text">
                    On <b>February 2020</b>, Steve founded and established <b>Australian Power Corporation</b> as the new flagship bearing the same tradition of excellence, 
                    extensive domestic and international network, years of professional experience and knowledge, reasonable pricing, and family-oriented service.
                </p>
              </section>
          </div>
      </div>
    );
}

export default AboutUs;

import React from 'react';
import './PowerStations.css';
import {useSpring, animated} from 'react-spring';
import ContactUs from '../../components/Mailer/ContactUs';
//import BannerCarousel from '../../components/Carousel/BannerCarousel';
import SigBeefCarousel from '../../components/Carousel/SigBeefCarousel';

const PowerStations = (props) => {
    const props1 = useSpring({opacity: 1, from: {opacity: 0}});
    return (
        <div>
            <animated.div style={props1}>
                <div className="ps-carousel-container">
                    <div className="ps-bg-img-container ps-bg-01">
                        <div style={{opacity:"0.45"}} className="ps-bg-text-container">
                            <h2 className="ps-mask" onClick={props.click}>Build, Own, Operate & Transmit</h2>
                            <br />
                            <h3><a href="/apc-staff" className="ps-link">Contact us</a> and see how we can power your industry.</h3>
                            <p className="ps-photo-origin"> 
                                Photo courtesy of Caterpillar Motoren
                            </p>
                        </div>
                    </div>
                </div>
            </animated.div>
            <div className="ps-content-grid-vertical">
                <div>
                    <div>
                        <img className="ps-header-photo" src={process.env.REACT_APP_BACKEND_URL + "/powerstation/apc-sigbeef-aerial.png"} alt="APC Powerstation Project - Signature Beef Abattoir"/>
                    </div>
                    <div className="ps-article-content-wrapper">
                            <h2 className="aboutus-header-text">
                                Signature Beef Abattoir - Power Station Project
                            </h2>
                            <p className="aboutus-content-text">
                                Australian Power Corporation has recently completed the commissioning of the 3400 KVA Diesel Power Station Project last 4th September 2021, at Moranbah, Queensland. The power station shall serve as the power provider for Signature Beef’s newest meat processing plant - the first abattoir built in Queensland in two decades. The boutique plant is said to process 200 heads per day.  
                            </p>
                            <p className="aboutus-content-text">
                                Click the links below for more details on Signature Beef abattoir:
                                <ul className="ps-list-01">
                                    <li key="ps_link1"><a href="https://www.facebook.com/7NEWSMackay/videos/257143949626304" className="ps-link" target="_new">https://www.facebook.com/7NEWSMackay/videos/257143949626304</a></li>
                                    <li key="ps_link2"><a href="https://www.abc.net.au/news/2021-03-15/moranbah-abattoir-construction-nears-end-work-opportunities/13243374" className="ps-link" target="_new">https://www.abc.net.au/news/2021-03-15/moranbah-abattoir-construction-nears-end-work-opportunities/13243374</a></li>
                                </ul>  
                                
                            </p>
                            <p className="aboutus-content-text">
                                The power plant is composed of two (2) Cummins KTTA50 (Rated 1200 KVA) and one (1) Cummins KTTA38 (Rated 1000KVA) diesel engines all with Stamford alternators on them. Each generator set is cooled by a corresponding Remote Horizontal Radiator each with VSD drive to control/manage the radiator fans.
                            </p>
                            <p className="aboutus-content-text">
                                Managing the 3-generator load-share system is the Deep Sea DSE8610 controllers. The controllers were pre-configured, pre-programmed, and customized by our engineers according to the specifications/needs of Signature Beef’s boutique plant. They monitor the generators and detect operational status and fault conditions. Upon detection of load demand or fault condition, they automatically start or stop the engine.
                            </p>
                            <p className="aboutus-content-text">
                                Notable load share features include:
                                
                                <ul className="ps-list-01">
                                    <li key="1st">Load Demand (with appropriate DSE mains controller)</li>
                                    <li key="2nd">Sequential set start</li>
                                    <li key="3rd">Auto voltage/frequency adjustment</li>
                                    <li key="4th">Generator load demand (start and stop)</li>
                                    <li key="5th">Automatic hours run balancing</li>
                                    <li key="6th">Dead bus sensing</li>
                                    <li key="7th">Direct governor and AVR control</li>
                                    <li key="8th">Volts and frequency matching</li>
                                    <li key="9th">kW and kvar load sharing</li>
                                    <li key="10th">Dead bus synchronising</li>
                                </ul>
                            </p>
                            <p className="aboutus-content-text">
                            True to our mission to power the Australian home, business, and lifestyle, we are proud to have taken part in this historic project for Queensland. Moving forward, we continue to seek partnerships that will help benefit the Australian people. You are welcome to contact us for consultation on the power needs of your next project.
                            </p>
                    </div>
                    <div>
                        <div className="sigbeef-carousel-container-div"><SigBeefCarousel/></div>
                    </div>
                </div>
                <div className="ps-content-grid">
                    <div>
                        <br />
                        <br />
                        <div className="contactus-container"><ContactUs /></div>
                    </div>
                    <div>
                        <div className="ps-content-wrapper">
                            <br />
                            <br />
                            <h2 className="aboutus-header-text">
                                The APC Difference
                            </h2>
                            <p className="aboutus-content-text">
                                APC prides itself as a veteran in the Power Station industry. We have been building and maintaining Power Stations since 1996. 
                            </p>
                            <p className="aboutus-content-text">
                                We build our power stations / power plants with only the most reliable generator brands and the latest technology stack. 
                                These translate to optimal fuel efficiency and ultimately result to lower cost of energy. We transport, install, and 
                                commission to ALL of Australia.
                            </p>
                            <br />
                            <br />
                            <h2 className="aboutus-header-text">
                                BOOT
                            </h2>
                            <p className="aboutus-content-text">
                                Under our Build, Own, Operate, and Transmit (BOOT) contract, we assume all the financial and technical risks associated 
                                with the power station / power plant project. With 35 years of experience, you can rely on our financial stability and 
                                technical expertise in all phases of the project.
                            </p>
                            <div className="ps-photo-grid">
                                <div>
                                    <img className="ps-photo" src={process.env.REACT_APP_BACKEND_URL + "/powerstation/welding01.jpg"} alt="Power Station - Frame Welding"/>
                                </div>
                                <div>
                                    <img className="ps-photo" src={process.env.REACT_APP_BACKEND_URL + "/powerstation/frame01.jpg"} alt="Power Station - Frame"/>
                                </div>
                                <div>
                                    <img className="ps-photo" src={process.env.REACT_APP_BACKEND_URL + "/powerstation/fanbase01.jpg"} alt="Power Station - Radiator Fan"/>
                                </div>
                                <div>
                                    <img className="ps-photo" src={process.env.REACT_APP_BACKEND_URL + "/powerstation/genset01.jpg"} alt="Power Station - Generators"/>
                                </div>
                                <div>
                                    <img className="ps-photo" src={process.env.REACT_APP_BACKEND_URL + "/powerstation/louvre01.jpg"} alt="Power Station - Louvres"/>
                                </div>
                                <div>
                                    <img className="ps-photo" src={process.env.REACT_APP_BACKEND_URL + "/powerstation/panel01.jpg"} alt="Power Station - Switch Panels"/>
                                </div>
                            </div>
                            <div className="ps-photo-grid-2columns">
                                <div>
                                    <img className="ps-photo-column-01" src={process.env.REACT_APP_BACKEND_URL + "/powerstation/Const01.jpg"} alt="Power Station - Commissioning"/>
                                </div>
                                <div>
                                    <img className="ps-photo-column-02" src={process.env.REACT_APP_BACKEND_URL + "/powerstation/Const02.jpg"} alt="Power Station - Commissioning"/>
                                </div>
                            </div>
                            <br />
                            <br />
                            <h2 className="aboutus-header-text">
                                High Availability Operation
                            </h2>
                            <p className="aboutus-content-text">
                                Our highly qualified and in-house trained engineers are deployed (on a shifting basis) in our power stations / power plants 
                                to monitor and maintain the plant operation 24/7. 
                            </p>
                            <p className="aboutus-content-text">
                                With engineers present on-site, you can have the flexibility of ramping up or scaling down the power supply depending on your
                                changing capacity demand. 
                            </p>
                            <br />
                            <br />
                        </div>
                    </div>
                    
                </div>
            </div>
            
        </div>
            
        
    )
}

export default PowerStations;
import React from 'react';
import Modal from 'react-bootstrap/Modal';
//import Button from 'react-bootstrap/Button';
import ProductDetails from '../../contents/Products/ProductDetails';
import './CustomModal.css';

const ProductDetailModal = (props) => {
  return (
    
      <Modal
        show={props.show}
        onHide={props.hide}
        dialogClassName="main-modal"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            <span className="modal-title">{props.product.Name}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProductDetails product={props.product}/>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="primary" onClick={props.hide}>
            Back to Browsing
          </Button>
        </Modal.Footer> */}
      </Modal>
    
  )
}

export default ProductDetailModal;


import React from 'react';
import {Link} from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';

export default function Submenuitem(props) {
    

    return (
        <div>
            {
                (props.newtab == true) && <Dropdown.Item as={Link} target={props.newtabname} to={props.Link} className="custom-underline">{props.Name}</Dropdown.Item>
            }
            
            {
                (props.newtab != true) && <Dropdown.Item as={Link} to={props.Link} className="custom-underline">{props.Name}</Dropdown.Item>
            }
        </div>
    )
};

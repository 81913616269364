import React, {Component} from "react";
import "./app.css"
//import "../../components/Toolbar/Toolbar.css";
import Toolbar from "../../components/Toolbar/Toolbar";
//import Backdrop from "../../components/Backdrop/Backdrop";
import AboutUs from "../../contents/AboutUs/AboutUs";
import HomePage from "../../contents/HomePage/HomePage";
import PowerStations from "../../contents/PowerStations/PowerStations";
import Machtraders from "../../contents/Machtrader/machtraders";
//import Footer from "../../components/Footer/Footer";
import Footer from "../../contents/Footer/Footer";
import {Switch,Route} from "react-router-dom";
import ProductList from "../../contents/Products/ProductList";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Nav from 'react-bootstrap/Nav';
//import Navbar from 'react-bootstrap/Navbar';
import TemporaryDrawer from '../../components/SideDrawer/TemporaryDrawer';
import RenderLinkWithSubMenu from '../../components/Toolbar/RenderLinkWithSubMenu';
import RenderNavBarLinks from '../../components/Toolbar/RenderLink';
import ProductPage from "../../contents/Products/ProductPage";
import ContactsPage from '../../contents/Contacts/ContactsPage';
import PortablesList from "../../contents/Products/PortablesList";
//import Login from '../../components/Login/login';
import { useParams } from "react-router";
import ReactGA from 'react-ga';
import M4U from "../../contents/Machines4u/m4u";

const App = () => {
    ReactGA.initialize('UA-174498279-1');
    const params = useParams();
    return (
      <div className="container-app">
        <Toolbar />
        <div className="container-body">
          <div className="apc-sticky">
            <Nav
              className="apc-custom"
              activeKey="/home"
              >
                  <Nav.Item>
                      <TemporaryDrawer />
                  </Nav.Item>
                   <div className="filler"/>
                  {/*{
                    (window.innerWidth <= 500) && <Nav.Item>
                      <a href="https://mobile.australianpower.net" className="custom-underline">Mobile Version</a>
                    </Nav.Item>
                  }  */}
                  <RenderNavBarLinks />
                  <div className="spacer-20"/>
                  <RenderLinkWithSubMenu />
                  <div className="filler"/>
            </Nav>
          </div>
          <div>
            <Switch>
              {/*<Route path="/" exact component={HomePage}/>*/}
              {(window.innerWidth >= 501) ? (
                <Route exact path="/" component={HomePage} />
                ) : (
                <Route exact path="/" render={() => (window.location = "https://mobile.australianpower.net")} />
              )}
              
              <Route path="/about-us" component={AboutUs}/>
              <Route path="/power-stations">
                <PowerStations/>
              </Route>
              <Route path="/diesel-generators">
                <ProductList menugroup="Products" category="Diesel Generator"/>
              </Route>

             {/*  <Route path="/portable-generators">
                <PortablesList menugroup="Portables" category="Portable Inverter Generator"/>
              </Route> */}
              
              {(window.innerWidth >= 501) ? (
                <Route path="/portable-generators">
                  <PortablesList menugroup="Portables" category="Portable Inverter Generator"/>
                </Route>  
                ) : (
                <Route path="/portable-generators" render={() => (window.location = "https://mobile.australianpower.net/portable-generators")} />
              )}

              <Route path="/silenced-diesel-generators">
                <ProductList menugroup="Products" category="Silenced Generator"/>
              </Route>
              <Route path="/preloved-equipment">
                <ProductList menugroup="Products" category="Pre-Loved Equipment"/>
              </Route>
              {/* <Route path="/tradeearthmovers">
                <Machtraders />
              </Route>
              <Route path="/tradeearthmovers" component={Machtraders} />
              <Route path="/m4u" component={M4U} />*/}
              <Route path="/m4u">
                <M4U />
              </Route>
              <Route path="/apc-staff">
                <ContactsPage />
              </Route>
              
              <Route path="/product/:productID" component={ProductPage} />
              <Route path='/mobile' component={() => { 
                window.location.href = 'https://mobile.australianpower.net'; 
                return null;
              }}/>
            </Switch>
          </div>
          
        </div>  
        <div className="container-footer">
            <Footer/>
        </div>
      </div>
    );
  }

export default App;
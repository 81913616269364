import React from "react";
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const RenderCompanyLogo = () => {
    const GET_COMPANYLOGO = gql`{
        companyLogo {
          id
          Name
          Link
        }
      }
    `;

    const { loading, error, data } = useQuery(GET_COMPANYLOGO);
    if (loading) return 'Loading...';
    if (error) return `Error! ${error.message}`;

    return (
        <div>
          <img className="toolbar_logo_image" src={process.env.REACT_APP_BACKEND_URL + data.companyLogo.Link} alt="Australian Power Corp" />
        </div>
    );
}

export default RenderCompanyLogo;
import React from 'react'
import './Toolbar.css';

function Buyerschoice() {

    return (
        <div>
            <a href='https://www.machines4u.com.au/directory/Australian-Power-Corporation/1800/awards' 
            title="Australian Power Corporation - Buyer's Choice Award"
            target='_blank' 
            class='buyerschoice_href'>
                <div class='buyreschoice_badge'>
                    <div class='buyerschoice_compnamecontainer'>
                        <span class='buyerschoice_span'>Australian Power Corporation</span>
                    </div>
                </div>
            </a>
        </div>        
    )
}

export default Buyerschoice;

import React from 'react';
import './ApcCategoryCard.scss';
import useWindowSize from '../../hooks/useWindowSize';
import {Link} from "react-router-dom";

const ApcCategoryCard = (props) => {
    const size = useWindowSize();
    var cardClassName = "";
    var cardRect = "";
    var cardTri = "";
    if (size.width < 1920) {
        cardClassName = "cards-small cards--two--small";
        cardRect = "cards--two--small__rect";
        cardTri = "cards--two--small__tri";
      } else {
        cardClassName = "cards cards--two";
        cardRect = "cards--two__rect";
        cardTri = "cards--two__tri";
      }
    return (
        <div className="card__collection clear-fix">
            <Link to = "/portable-generators" >
                <div className={cardClassName}>
                    <img src={process.env.REACT_APP_BACKEND_URL + '/homepage/categories/portable-generators.png'} className="img-responsive"></img>
                    <span className={cardRect}></span>
                    <span className={cardTri}></span>
                    <p>Portable <div className="subtitle">Generators & Water Pumps</div></p>
                    
                </div>
            </Link>
            <Link to = "/power-stations" >
                <div className={cardClassName}>
                    <img src={process.env.REACT_APP_BACKEND_URL + '/homepage/categories/powerstations.png'} className="img-responsive"></img>
                    <span className={cardRect}></span>
                    <span className={cardTri}></span>
                    <p>Power Station <div className="subtitle">Build, Own, Operate, & Transfer</div></p>
                    
                </div>
            </Link>
            <Link to = "/silenced-diesel-generators" >
                <div className={cardClassName}>
                    <img src={process.env.REACT_APP_BACKEND_URL + '/homepage/categories/silencedgenset.png'} className="img-responsive"></img>
                    <span className={cardRect}></span>
                    <span className={cardTri}></span>
                    <p>Silenced Diesel <br/> Generator</p>
                    
                </div>
            </Link>
            <Link to = "/diesel-generators" >
                <div className={cardClassName}>
                    <img src={process.env.REACT_APP_BACKEND_URL + '/homepage/categories/dieselgenset.png'} className="img-responsive"></img>
                    <span className={cardRect}></span>
                    <span className={cardTri}></span>
                    <p>Diesel <br/> Generator</p>
                    
                </div>
            </Link>           
            <Link to = "/preloved-equipment" >
                <div className={cardClassName}>
                    <img src={process.env.REACT_APP_BACKEND_URL + '/homepage/categories/prelovedeqpt.png'} className="img-responsive"></img>
                    <span className={cardRect}></span>
                    <span className={cardTri}></span>
                    <p>Pre-Loved <br/> Equipment</p>
                    
                </div>
            </Link> 
             
        </div>
    )
}

export default ApcCategoryCard;
import React from "react";
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import './Nav.css';
//import {Link} from "react-router-dom";
import Submenuitem from '../../components/Toolbar/SubMenuItem';
import DropdownButton from 'react-bootstrap/DropdownButton'
//import Dropdown from 'react-bootstrap/Dropdown'
import 'bootstrap/dist/css/bootstrap.css';


const RenderLinkWithSubMenu = () => {
    const GET_DROPDOWNBARS = gql`{
        mainBars(where: {Active: true, WithSub: true}) {
          id
          AppOrder
          Name
          Link
          Description
          WithSub
          sub_bars{
            id
            Name
            Link
            AppOrder
            newtab
            newtabname
          }
        }
      }
    `;

    const { loading, error, data } = useQuery(GET_DROPDOWNBARS);
    if (loading) return 'Loading...';
    if (error) return `Error! ${error.message}`;

    return (
        data.mainBars.map(bar=>(
        <DropdownButton id="dropdown-basic-button1" className="apc-dropdown" title={bar.Name}>
            {
                bar.sub_bars.map(item=>(
                  <Submenuitem Link={item.Link} newtab={item.newtab} newtabname={item.newtabname} Name={item.Name}></Submenuitem>
                ))
                
            }
            
        </DropdownButton>
        ))
        
    );
}

export default RenderLinkWithSubMenu;
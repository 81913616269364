import React from 'react';
import Card from 'react-bootstrap/Card';
import './ProductFilter.css';

const CategoryFilter = (props) => {
    return (
        <div className="apc-categories-container">
            <Card>
                <Card.Header className="apc-categories-header">Categories</Card.Header>
                <Card.Text>
                    {props.categories.map((cat) =>
                        <div className="custom-control custom-checkbox apc-checkbox-container">
                            {(props.active == cat.Name) && <input type="radio" value={cat.Name} name="categories" className="custom-control-input" id={cat.Name} defaultChecked onClick={props.change}/>}
                            {(props.active != cat.Name) && <input type="radio" value={cat.Name} name="categories" className="custom-control-input" id={cat.Name} onClick={props.change}/>}
                            <label className="custom-control-label" for={cat.Name}>{cat.Name}</label>
                        </div>
                    )}
                </Card.Text>    
            </Card>
        </div>
    );
}

export default CategoryFilter;
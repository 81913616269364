import React from 'react';
import NumberFormat from 'react-number-format';
import ProductDetailsCarousel from '../../components/Carousel/ProductDetailsCarousel';
import MarkdownView from 'react-showdown';
import ListGroup from 'react-bootstrap/ListGroup';
import EnquireProductForm from '../../components/Mailer/EnquireProduct';
import {AwesomeButton} from 'react-awesome-button';
import './ProductCard.css';
import '../../../node_modules/react-awesome-button/dist/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPaperPlane, faWindowClose} from '@fortawesome/free-solid-svg-icons';

const ProductDetails = (props) => {
    const [showEnquiry, setShowEnquiry] = React.useState(false);
    return (
        //<div className="row">
            <div className="container-productdetails-grid">
                
                <div className="carousel-container">
                    <ProductDetailsCarousel 
                        Photos={props.product.productPhoto} 
                        ProductName={props.product.Name} 
                        ProductPrice={<NumberFormat value={props.product.Price} displayType={'text'} thousandSeparator={true} prefix={'$'} />} 
                    /> 
                    {
                        showEnquiry && (
                            <div className="emailform-container">
                            <EnquireProductForm ItemName={props.product.Name} click={() => setShowEnquiry(!showEnquiry)}/>
                            </div>
                        )
                    }
                    <div className="price-container">
                    <div className="email-price-row">
                        <div className="email-price-column">
                            {/* Price: {<NumberFormat value={props.product.Price} displayType={'text'} thousandSeparator={true} prefix={'$'} />} */}
                            {
                                (props.product.OnSale === true) && (
                                    <h5><span className="prev-price">(<NumberFormat value={props.product.Price} displayType={'text'} thousandSeparator={true} prefix={'$'} />)</span> <NumberFormat value={props.product.SalePrice} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                                
                                )
                            }

                            {
                                (!props.product.OnSale) && (<h5><NumberFormat value={props.product.Price} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>)
                            }
                        </div>
                        <div className="email-price-column" > </div>
                        <div className="email-price-column">
                            {
                                !showEnquiry && (
                                    <AwesomeButton type="primary" onPress={() => setShowEnquiry(!showEnquiry)}><FontAwesomeIcon icon={faPaperPlane} /> enquire thru email</AwesomeButton>
                                )    
                            }
                            
                            {
                                showEnquiry && (
                                    <AwesomeButton type="secondary" onPress={() => setShowEnquiry(!showEnquiry)}><FontAwesomeIcon icon={faWindowClose} /> close enquiry form</AwesomeButton>
                                )
                            }
                        </div>
                    </div>
                </div>
                </div>
                
            {/* </div> */}
            <div className="column">
                <div className="details-container">
                    <ListGroup className="list-group-flush">
                        <ListGroup.Item>
                            <MarkdownView
                                markdown={props.product.Description}
                                options={{ tables: true, emoji: true }}
                            />
                        </ListGroup.Item> 
                        <ListGroup.Item className="productdetail-url">{process.env.REACT_APP_FRONTEND_URL + "/product/" + props.product.id }</ListGroup.Item>
                    </ListGroup>
                </div>
            </div>
       </div>
    )
}

export default ProductDetails;

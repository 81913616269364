import React from "react";
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const RenderCompanySpiel = () => {
    const GET_SPIEL = gql`{
        spiels (where: {Visible: true}){
          id
          Spiel
          Visible
        }
      }
    `;

    const { loading, error, data } = useQuery(GET_SPIEL);
    if (loading) return 'Loading...';
    if (error) return `Error! ${error.message}`;

    return (
        <div>
            
            <ul className="spiel">
            {
              data.spiels.map(spiel =>
                <li key={spiel.id}> {spiel.Spiel} </li>
              )
             }
            </ul>  
        </div>
    );
}

export default RenderCompanySpiel;
import React from 'react'
import './Nav.css';
//import Nav from 'react-bootstrap/Nav';
//import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.css';
import ToolbarTop from './ToolbarTop';
//import TemporaryDrawer from '../SideDrawer/TemporaryDrawer';
//import RenderLinkWithSubMenu from './RenderLinkWithSubMenu';
//import RenderNavBarLinks from './RenderLink';

const Toolbar = () => {
    return (
        //<header> 
        <div className="toolbar_container"> 
            
            <ToolbarTop />
                    {/* <Nav
                    className="navbar sticky-top apc-custom"
                    activeKey="/home"
                    onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
                    >
                        <Nav.Item>
                            <TemporaryDrawer />
                        </Nav.Item>
                        <div className="filler"/>
                        <RenderNavBarLinks />
                        <div className="spacer-20"/>
                        <RenderLinkWithSubMenu />
                        <div className="filler"/>
                    </Nav>       */}
                
         </div>
        //</header>  
            
    )
}

export default Toolbar
import React, {useState, useEffect} from 'react';
import WoyPaginationHook from '../../components/Pagination/WoyPaginationHook';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import ProductCardUI from './ProductCardUI';
import ProductDetailModal from '../../components/Modal/CustomModal';
import Spinner from 'react-bootstrap/Spinner';
import Pagination from 'react-bootstrap/Pagination';
import './ProductCard.css';
import './Paginator.scss';
import CategoryFilter from '../../components/ProductFilter/CategoryFilter';
import ProductFilter from '../../components/ProductFilter/ProductFilter';
import PriceFilter from '../../components/ProductFilter/PriceFilter';
import ReactGA from 'react-ga';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

const range = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
}

const ProductList = (props) => {
  const [show, setShow] = useState(false);
  const [selectedProduct, selectProduct] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [category, setCategory] = useState(props.category);
  const [brandnew, setBrandNew] = useState(false);
  const [search, setSearch] = useState("");
  const [maxprice, setMaxPrice] = useState(500000.00);
  const [minprice, setMinPrice] = useState(1000.00);
  const [maxpriceevt, setMaxPriceEvt] = useState(500000.00);
  const [minpriceevt, setMinPriceEvt] = useState(1000.00);

  // const removeCategory = (catValue) => {
  //   var tempCat = categories;
  //   var index = tempCat.indexOf(catValue);
  //   tempCat.splice(index, 1);
  //   setCategories(tempCat);
  // } 

  // const addCategory = (catValue) => {
  //   var tempCat = categories;
  //   tempCat.push(catValue);
  //   setCategories(tempCat);
  // }

  // const handleInputChange = (event) => {
  //   const target = event.target;
  //   const value = target.value;
  //   if (target.checked) {
  //     addCategory(value);
  //   } else {
  //     removeCategory(value);
  //   }
  //   alert(categories);
  // }

  const handleCategoryInputChange = (event) => {
    //if(event.target.checked) {
      setCategory(event.target.value);
   // }
    //alert(category);
  }

  const handleBrandNewInputChange = (event)=> {
    setBrandNew(event.target.value);
  }

  const handleSearchInputChange = (event)=> {
    if (event.key === "Enter") {
      setSearch(event.target.value);
    }
  }

  const handleMinPriceInputChange = (event)=> {
    if (event.target.validity.valid) {
      setMinPriceEvt(event.target.value);
    } else {
      alert("Please enter a valid price")
    }
  }

  const handleMaxPriceInputChange = (event)=> {
    if (event.target.validity.valid) {
      setMaxPriceEvt(event.target.value);
    } else {
      alert("Please enter a valid price")
    }
  }

  const handlePricingClick = () =>{
    setMinPrice(minpriceevt);
    setMaxPrice(maxpriceevt);
  }


  const GET_PRODUCTS = gql`{
    productCategories(where:{
      menugroup:"${props.menugroup}"
    }){	
      id
     Name
     Description
    },

    products(sort:"created_at:DESC", where:{
      Active:true,
      Price_gte:${minprice},
      Price_lte:${maxprice},
      BrandNew:${brandnew},
      Description_contains:"${search}",
      product_categories: {Name: "${category}"}
    }){
      id
      Name
      Model
      Description
      ShortDesc
      BrandNew
      Condition
      Price
      created_at
      FeaturePhoto{
        id
        provider
        url
      }
      product_categories{
        id
        Name
        Description
      }
      productPhoto{
        id
        provider
        url
      }
      
    }
  }
`;
const { loading, error, data } = useQuery(GET_PRODUCTS);
if (loading) return (
  <div>
    <Spinner animation="border" variant="success" />
    <p>Loading...</p>
  </div>
  )
if (error) return `Error! ${error.message}`;

  const {next, prev, jump, currentData, maxPage, begin, end} = WoyPaginationHook(data.products,8,currentPage,setCurrentPage);
  const currData = currentData();

  ReactGA.initialize('UA-174498279-1');
  ReactGA.pageview(category);

  return (
      <div className="productlist-wrapper">
        <div className="productlist-search-container">
          {
            (props.menugroup=="Products") && <CategoryFilter active={category} categories={data.productCategories} change={handleCategoryInputChange}/>
          }
          
          <ProductFilter change={handleBrandNewInputChange} search={handleSearchInputChange} defvalue={search}/>
          <PriceFilter minprice={minpriceevt} maxprice={maxpriceevt} minpricing={handleMinPriceInputChange} maxpricing={handleMaxPriceInputChange} pricingclick={handlePricingClick}/>
        </div>
        <div>
          <div className="container-productlist-pageoptions">
            <div className="container-paginator">
              <b>{data.products.length}</b> product(s) | {(data.products.length > 0) && <span>showing <b>{begin} - {end}</b></span>} 
            </div>
            {
              (maxPage > 1) && 
                  <div className="container-paginator"><Paginator maxPage={maxPage} currentPage={currentPage} jump={jump} next={next} prev={prev} pageNeighbours={2}/></div>
            }
          </div>
          <div id="product" className="container-grid">
          {
            currData.map((product)=>
              <div className="grid-item">
                <ProductCardUI product={product} click={ () => {
                  setShow(true);
                  selectProduct(product);
                  ReactGA.event({
                    category: 'User',
                    action: `Viewed Product - ${product.Name}`
                  });
                }} />
              </div>
            )
          } 
          <ProductDetailModal product={selectedProduct} show={show} hide={() => setShow(false)} />
        </div>
      </div>
      </div>
  )
} 

const fetchPageNumbers = (totalPages, currentPage, pageNeighbours) => {
   /**
   * totalNumbers: the total page numbers to show on the control
   * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
   */
  const totalNumbers = (pageNeighbours * 2) + 3;
  const totalBlocks = totalNumbers + 2;

  if (totalPages > totalBlocks) {

    const startPage = Math.max(2, currentPage - pageNeighbours);
    const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);

    let pages = range(startPage, endPage);

    /**
     * hasLeftSpill: has hidden pages to the left
     * hasRightSpill: has hidden pages to the right
     * spillOffset: number of hidden pages either to the left or to the right
     */
    const hasLeftSpill = startPage > 2;
    const hasRightSpill = (totalPages - endPage) > 1;
    const spillOffset = totalNumbers - (pages.length + 1);

    switch (true) {
      // handle: (1) < {5 6} [7] {8 9} (10)
      case (hasLeftSpill && !hasRightSpill): {
        const extraPages = range(startPage - spillOffset, startPage - 1);
        pages = [LEFT_PAGE, ...extraPages, ...pages];
        break;
      }

      // handle: (1) {2 3} [4] {5 6} > (10)
      case (!hasLeftSpill && hasRightSpill): {
        const extraPages = range(endPage + 1, endPage + spillOffset);
        pages = [...pages, ...extraPages, RIGHT_PAGE];
        break;
      }

      // handle: (1) < {4 5} [6] {7 8} > (10)
      case (hasLeftSpill && hasRightSpill):
      default: {
        pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
        break;
      }
    }

    return [1, ...pages, totalPages];

  }

  return range(1, totalPages);

}

const Paginator = ({maxPage, currentPage, jump, next, prev, pageNeighbours}) => {
  
  const pages = fetchPageNumbers(maxPage, currentPage, pageNeighbours);

  return (
    <Pagination>
      { pages.map((page, index) => {

        if (page === LEFT_PAGE) return (
          <Pagination.Prev className="page-item" onClick={()=>prev} />
        );

        if (page === RIGHT_PAGE) return (
          <Pagination.Next className="page-item" onClick={()=>next} />
        );

        return (
          <Pagination.Item key={index} className={`page-item${ page === currentPage ? ' active' : ''}`} onClick={()=>jump(page)}>
           {page}
          </Pagination.Item>
        );

        }) }
    </Pagination>
  );
}

export default ProductList;

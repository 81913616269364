import React from 'react';

function WoyPaginationHook (data, itemsPerPage, currentPage, setCurrentPage){
   
    const maxPage = Math.ceil(data.length / itemsPerPage);
    const begin = ((currentPage-1) * itemsPerPage)+1;
    var end = (begin-1) + itemsPerPage;

    if (currentPage == maxPage) {
        end = data.length;
    }
    
    function next() {
        setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage));
    }
    
    function prev() {
        setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
    }
    
    function jump(page) {
        const pageNumber = Math.max(1, page)
        setCurrentPage((currentPage) => Math.min(pageNumber, maxPage));
    }

    function currentData() {
        const begin1 = (currentPage - 1) * itemsPerPage;
        const end1 = begin1 + itemsPerPage;
        const currData = data.slice(begin1, end1); 
        //alert('begin: '+ begin + ' end:' + end + ' json:' + JSON.stringify(currData));
        return data.slice(begin1, end1);
    }

    return { next, prev, jump, currentData, currentPage, maxPage, begin, end };
}

export default WoyPaginationHook;

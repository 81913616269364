import React from "react";
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const RenderCompContactBold = () => {

  const GET_COMPCONTACT = gql`{
    companyContactDetails (where: {Visible: true, ContactType: "Phone"}){
      id
      ContactType
      ContactData
      Visible
    }
  }
`;

const { loading, error, data } = useQuery(GET_COMPCONTACT);
if (loading) return 'Loading...';
if (error) return `Error! ${error.message}`;

return(
    <div className="contact-column-inside">
      <ul>
        {
        data.companyContactDetails.map (contact => (
            <li className="compcontact-bold">  {contact.ContactData} </li>
        ))
        }
      </ul>
    </div>
);
}

export default RenderCompContactBold;
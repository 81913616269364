import React from 'react';
import Modal from 'react-bootstrap/Modal';
//import Button from 'react-bootstrap/Button';
import EnquireProductForm from '../../components/Mailer/EnquireProduct';
import './CustomModal.css';
import ProductDetailsCarousel from '../../components/Carousel/ProductDetailsCarousel';
import NumberFormat from 'react-number-format';

const ProductEnquireModal = (props) => {
  return (
    
      <Modal
        show={props.show}
        onHide={props.hide}
        dialogClassName="main-modal"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            <span className="modal-title">{props.title}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="container-productdetails-grid">
                
                <div className="carousel-container">
                    <ProductDetailsCarousel 
                        Photos={props.product.productPhoto} 
                        ProductName={props.product.Name} 
                        ProductPrice={<NumberFormat value={props.product.Price} displayType={'text'} thousandSeparator={true} prefix={'$'} />} 
                    /> 
                    <div className="price-container">
                    <div className="email-price-row">
                        <div className="email-price-column">
                            Price: {<NumberFormat value={props.product.Price} displayType={'text'} thousandSeparator={true} prefix={'$'} />}
                        </div>
                        <div className="email-price-column" > </div>
                        <div className="email-price-column"> </div>
                    </div>
                </div>
                </div>
                
            {/* </div> */}
            <div className="column">
                <div className="details-container">
                  <EnquireProductForm ItemName={props.product.Name}/>
                </div>
            </div>
       </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="primary" onClick={props.hide}>
            Back to Browsing
          </Button>
        </Modal.Footer> */}
      </Modal>
    
  )
}

export default ProductEnquireModal;


import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCaretRight} from '@fortawesome/free-solid-svg-icons';
import ContactUs from '../../components/Mailer/ContactUs';
import './ContactDrawer.css';

const ContactDrawer = (props) => {
    const [showForm, setShowForm] = useState(false);

    return (
        <div className="apc-contact-drawer-container">
            <div className="apc-contact-us-button" onClick={()=>{setShowForm(!showForm)}}>
                <div className="apc-contact-us-arrow">
                    <h4><FontAwesomeIcon icon={faCaretRight} /></h4>
                </div>
                <div>
                    <span className="apc-contact-us-text">Contact us</span>
                </div>
            </div>
            {
                showForm && (
                    <div className="apc-contact-drawer-overlay-bg">
                        <ContactUs click={()=>{setShowForm(false)}}/>
                    </div>
                ) 
            }
                
        </div>
    )
}

export default ContactDrawer;

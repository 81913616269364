import React from 'react';
import useWindowSize from '../../hooks/useWindowSize';
import BannerCarousel from '../Carousel/BannerCarousel';
import './Banner.css';

const Banner = () => {
  const size = useWindowSize();
  const banner_leftcol = "banner-leftcol";
  const banner_centercol = "banner-centercol";
  const banner_rightcol = "banner-rightcol";
  var banner_grid_container = "";
  var banner_img_container = "";
  var banner_carousel_container_div = "";
  
  if (size.width < 1920) {
    banner_grid_container = "banner-grid-container-small";
    banner_img_container = "banner-img-container-small";
    banner_carousel_container_div = "banner-carousel-container-div-small";
  } else {
    banner_grid_container = "banner-grid-container";
    banner_img_container = "banner-img-container";
    banner_carousel_container_div = "banner-carousel-container-div";
  }

  return (
    <div className={banner_grid_container}>
      <div className={banner_leftcol}></div>
      <div className={banner_centercol}>
        <div>
          <img className={banner_img_container} src={process.env.REACT_APP_BACKEND_URL + '/homepage/announcement/adimg.gif'}/>
        </div>
        <div className={banner_carousel_container_div}>
          {/* <img className="banner-carousel-container" src="http://localhost:3000/APC-PORTABLES-mobile-business-jobsite-leisure-1.png"/> */}
          
          <BannerCarousel/>
        </div>  
      </div>
      <div className={banner_rightcol}></div>
    </div>
  );
}

export default Banner;

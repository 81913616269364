import React from "react";
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import './Toolbar.css';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  root: {
    width: 250,
    maxWidth: 250,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const RenderSideDrawerLinks = (props) => {
  const classes = useStyles();

    const GET_LINKBARS = gql`{
        mainBars(where: {Active: true, WithSub: false}) {
          id
          AppOrder
          Name
          Link
          Description
          WithSub
        }
      }
      `;

    const { loading, error, data} = useQuery(GET_LINKBARS);
    if (loading) return 'Loading...';
    if (error) return `Error! ${error.message}`;
    
    return (
      <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
      >
        {data.mainBars.map((mainbar, index) => (
          <ListItem button component="a" href={mainbar.Link} key={mainbar.Name} onClick={props.click}>
            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
            <ListItemText primary={mainbar.Name} />
          </ListItem>
        ))}
      </List>
    );
}

export default RenderSideDrawerLinks;
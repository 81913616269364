import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import ProductDetails from './ProductDetails';
import { useParams } from "react-router";

const ProductPage = (props) => {
    const params = useParams();
    const GET_PRODUCT = gql`{ 
        products(where:{id:${params.productID}}
        ){
          id
          Name
          Model
          Description
          ShortDesc
          BrandNew
          Condition
          Price
          FeaturePhoto{
            id
            provider
            url
          }
          product_categories{
            id
            Name
            Description
          }
          productPhoto{
            id
            provider
            url
          }
          
        }
      }
    `;
    const { loading, error, data } = useQuery(GET_PRODUCT);
    if (loading) return (
      <div>
        <p>Loading...</p>
      </div>
      )
    if (error) return `Error! ${error.message}`;

    return (
        <div>
            <ProductDetails product={data.products[0]}/>
        </div>
    )
}

export default ProductPage;

import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
//import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import DrawerToggleButton from './DrawerToggleButton';
import RenderSideDrawerLinks from '../Toolbar/SideDrawerRenderLink';
import MenuAccordion from '../Accordion/MenuAccordion';
import RenderDrawerLogo from '../Logo/DrawerLogo';

const useStyles = makeStyles((theme) =>({
  list: {
    width: 250,
    backgroundColor: theme.palette.background.paper,
  },
  fullList: {
    width: 'auto',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function TemporaryDrawer (props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
      <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      //onClick={toggleDrawer(anchor, false)}
      //onKeyDown={toggleDrawer(anchor, false)}
    >
      <RenderDrawerLogo />
    <RenderSideDrawerLinks click={toggleDrawer}/>
    <MenuAccordion />
    </div>
  );

  return (
    <div>
        <React.Fragment key="left">
          <DrawerToggleButton click={toggleDrawer("left", true)}/>
          <Drawer anchor="left" open={state["left"]} onClose={toggleDrawer("left", false)}>
            {list("left")}
          </Drawer>
        </React.Fragment> 
    </div>
  );
}
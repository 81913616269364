import React from 'react';
import ApcCategoryCard from '../../components/CategoryCards/ApcCategoryCard';
import './Homepage.css';
import ContactDrawer from '../../components/Mailer/ContactDrawer';
import ReactGA from 'react-ga';
import Banner from '../../components/Banner/Banner';
import useWindowSize from '../../hooks/useWindowSize';

const HomePage = () => {
    ReactGA.initialize('UA-174498279-1');
    ReactGA.pageview('Home');
    const size = useWindowSize();
    var homepage_content_row = "";
    var category_label = "";
    var wrapper_category = "";
    var img_category_label = "";
    if (size.width < 1920) {
        homepage_content_row = "homepage-content-row-small";
        category_label = "category-label-small";
        wrapper_category = "wrapper-category-small";
        img_category_label = "img_category_label_small";
    } else {
        homepage_content_row = "homepage-content-row";
        category_label = "category-label";
        wrapper_category = "wrapper-category";
        img_category_label = "img_category_label";
    }
    return (
        <div>
            <div id="content-desktop">
                <div>
                    <Banner/>
                </div>
                <div className={homepage_content_row}>
                    <div></div>
                    <div className={wrapper_category}>
                        <div className={category_label}>
                            <img className={img_category_label} src={process.env.REACT_APP_BACKEND_URL + '/homepage/category-label.png'}/>
                        </div>
                        <div className="container-category-cards">
                            <div className="w3-container"><ApcCategoryCard /></div>
                        </div>
                    </div>
                    <div></div>
                </div>

                <div className={homepage_content_row}>
                    <div></div>
                    <div>
                        <div className="wrapper-content">
                            <section className="section is-medium">
                                <div className="content-grid">
                                    <div className="container-text">
                                        <p className="text-content">
                                            35 years in the generator industry - We service and supply power products, compressed air equipment, and earth-moving machinery. We build custom power requirements - from installation of generators for home, job sites, mobile projects, buildings, & business establishments, to building power stations for factories, mining, towns, and whole communities.     
                                        </p>
                                        <p className="text-content-sub">
                                            With expert knowledge and years of experience, our team can assist in any project big or small.
                                        </p>
                                        <div className="apc-contact-container">
                                            <ContactDrawer />
                                        </div>
                                    </div>
                                    <div className="container-profilepic">
                                        <img 
                                            className="profilepic"
                                            src={process.env.REACT_APP_BACKEND_URL + "/homepage/profilepic.jpg"} 
                                            alt="Steve Clements - Founder and Managing Director of Australian Power Corporation" 
                                        />
                                        <div>
                                            <span className="text-content-name">
                                                Steve Clements
                                            </span> <br />
                                            <span className="text-content-sub">
                                                Founder and Managing Director
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                    <div></div>
                </div>
        </div>
        </div>
    )
}

export default HomePage;

import React from "react";
import Slider from "react-slick";
//import useWindowSize from '../../hooks/useWindowSize'
import '../../components/Banner/Banner.css';


const SigBeefCarousel = (props) => {      
  //const size = useWindowSize();
  var banner_carousel_container = "sigbeef-carousel-container";

  /* if (size.width < 1920) {
    banner_carousel_container  = "banner-carousel-container-small";
  } else {
    banner_carousel_container  = "sigbeef-carousel-container";
  }
 */
  var settings = {
    dots: true,
    infinite: true,
    speed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  }; 

  return (  
    <Slider {...settings}>
      {/* <div>
        <img
            className={banner_carousel_container}
            src={process.env.REACT_APP_BACKEND_URL + '/powerstation/sigbeef/01.png'}
            alt={"aerial_view"}
        />
      </div> */}
      <div>
        <img
            className={banner_carousel_container}
            src={process.env.REACT_APP_BACKEND_URL + '/powerstation/sigbeef/02.jpg'}
            alt={"genset_panel"}
        />
      </div> 
      <div>
      <img
            className={banner_carousel_container}
            src={process.env.REACT_APP_BACKEND_URL + '/powerstation/sigbeef/03.jpg'}
            alt={"aerial_02"}
        />
      </div>  
      <div>
        <img
            className={banner_carousel_container}
            src={process.env.REACT_APP_BACKEND_URL + '/powerstation/sigbeef/04.jpg'}
            alt={"photo_04"}
        />
      </div>
      <div>
        <img
            className={banner_carousel_container}
            src={process.env.REACT_APP_BACKEND_URL + '/powerstation/sigbeef/05.jpg'}
            alt={"photo_05"}
        />
      </div> 
      <div>
        <img
            className={banner_carousel_container}
            src={process.env.REACT_APP_BACKEND_URL + '/powerstation/sigbeef/06.jpg'}
            alt={"photo_06"}
        />
      </div>     
      <div>
        <img
            className={banner_carousel_container}
            src={process.env.REACT_APP_BACKEND_URL + '/powerstation/sigbeef/07.jpg'}
            alt={"photo_07"}
        />
      </div> 
      <div>
        <img
            className={banner_carousel_container}
            src={process.env.REACT_APP_BACKEND_URL + '/powerstation/sigbeef/08.jpg'}
            alt={"photo_08"}
        />
      </div> 
      <div>
        <img
            className={banner_carousel_container}
            src={process.env.REACT_APP_BACKEND_URL + '/powerstation/sigbeef/09.jpg'}
            alt={"photo_09"}
        />
      </div> 
      <div>
        <img
            className={banner_carousel_container}
            src={process.env.REACT_APP_BACKEND_URL + '/powerstation/sigbeef/10.jpg'}
            alt={"photo_10"}
        />
      </div> 
      <div>
        <img
            className={banner_carousel_container}
            src={process.env.REACT_APP_BACKEND_URL + '/powerstation/sigbeef/11.jpg'}
            alt={"photo_11"}
        />
      </div> 
      <div>
        <img
            className={banner_carousel_container}
            src={process.env.REACT_APP_BACKEND_URL + '/powerstation/sigbeef/12.jpg'}
            alt={"photo_12"}
        />
      </div> 
      <div>
        <img
            className={banner_carousel_container}
            src={process.env.REACT_APP_BACKEND_URL + '/powerstation/sigbeef/13.jpg'}
            alt={"photo_13"}
        />
      </div> 
      <div>
        <img
            className={banner_carousel_container}
            src={process.env.REACT_APP_BACKEND_URL + '/powerstation/sigbeef/14.jpg'}
            alt={"photo_14"}
        />
      </div> 
      <div>
        <img
            className={banner_carousel_container}
            src={process.env.REACT_APP_BACKEND_URL + '/powerstation/sigbeef/15.jpg'}
            alt={"photo_15"}
        />
      </div> 
      <div>
        <img
            className={banner_carousel_container}
            src={process.env.REACT_APP_BACKEND_URL + '/powerstation/sigbeef/16.jpg'}
            alt={"photo_16"}
        />
      </div> 
      <div>
        <img
            className={banner_carousel_container}
            src={process.env.REACT_APP_BACKEND_URL + '/powerstation/sigbeef/17.jpg'}
            alt={"photo_17"}
        />
      </div> 
      <div>
        <img
            className={banner_carousel_container}
            src={process.env.REACT_APP_BACKEND_URL + '/powerstation/sigbeef/18.jpg'}
            alt={"photo_18"}
        />
      </div> 
      <div>
        <img
            className={banner_carousel_container}
            src={process.env.REACT_APP_BACKEND_URL + '/powerstation/sigbeef/19.jpg'}
            alt={"photo_19"}
        />
      </div> 
      <div>
        <img
            className={banner_carousel_container}
            src={process.env.REACT_APP_BACKEND_URL + '/powerstation/sigbeef/20.jpg'}
            alt={"photo_20"}
        />
      </div> 
    </Slider>
  );
}

export default SigBeefCarousel;

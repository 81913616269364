import React from 'react';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import {AwesomeButton} from 'react-awesome-button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faInfoCircle, faShoppingBag} from '@fortawesome/free-solid-svg-icons';

const HoverCard = styled.div`
	:hover {
        box-shadow: 5px 10px 20px 1px rgba(0,0,0,0.253) !important;
        
	}
`

const ProductCardUI = (props) => {
    return (
        
        <HoverCard className="card text-center shadow rounded">
            {
                (props.product.OnSale) && <span class="wdp-ribbon wdp-ribbon-two">{props.product.SaleTag}</span>
            }
            <div className="overflow">
                <img className="card-img-top" src={process.env.REACT_APP_BACKEND_URL + props.product.FeaturePhoto.url} alt={props.product.Name}/>
            </div>
            <div className="card-body text-dark">
                <div className="product-title-container">
                    <h6 className="card-title">{props.product.Name}</h6>
                    {
                       (props.product.OnSale === true) && (
                        <h5 className="card-title"><span className="prev-price">(<NumberFormat value={props.product.Price} displayType={'text'} thousandSeparator={true} prefix={'$'} />)</span> <NumberFormat value={props.product.SalePrice} displayType={'text'} thousandSeparator={true} prefix={'$'} /> <span className="ex-gst">excl GST</span></h5>
                       
                       )
                    }

                    {
                        (!props.product.OnSale) && (<h5 className="card-title"><NumberFormat value={props.product.Price} displayType={'text'} thousandSeparator={true} prefix={'$'} /><span className="ex-gst"> excl GST</span></h5>)
                    }
                </div>
                <div className="shortdesc-container">
                    <p className="card-text text-secondary">
                        {props.product.ShortDesc}
                    </p>cd 
                </div>
                <AwesomeButton type="primary" onPress={props.click}><FontAwesomeIcon icon={faInfoCircle} /> details</AwesomeButton>
                {
                    (props.product.EcomTag1==='Order') && (<AwesomeButton type="secondary" href={props.product.EcomLink1} target="_order1" ><FontAwesomeIcon icon={faShoppingBag} /> {props.product.EcomTag1}</AwesomeButton>)
                }
            </div>

        </HoverCard >    
        
    )
}

export default ProductCardUI;

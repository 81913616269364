import React from 'react';
import './m4u.css';
//import Iframe from 'react-iframe';

export default function M4U(props) {
    return (
        <div class="body_container">
            <iframe class="iframe_container" title="Machines4u" src="https://australianpowercorporation.trado.com.au/?thd=0&view=0&embedded=1&bg_color=fffff0&txt_color=000000&btn_color=ec6b21&btn_txt_color=353c4a&secondary_txt_color=000000&hyperlink_color=353c4a&tpn=1300 571 747"></iframe>
        </div>
    )
}

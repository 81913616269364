import React from "react";
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Card from 'react-bootstrap/Card';
import '../../components/SideDrawer/SideDrawer.css';

const RenderDrawerLogo = () => {
    const GET_COMPANYLOGO = gql`{
        drawerLogo {
          id
          FileName
          Description
        }
      }
    `;

    const { loading, error, data } = useQuery(GET_COMPANYLOGO);
    if (loading) return 'Loading...';
    if (error) return `Error! ${error.message}`;

    return (
        <div className="drawer_logo_container">
        <Card 
        bg='dark'
        key='dark-key'
        text='white'
        style={{ width: '99%', height:'200px'}}
        className="mx-auto"
        >
        
        {/*<Card.Header> 
            <img className="toolbar_logo_image" src={process.env.REACT_APP_BACKEND_URL + data.drawerLogo.FileName} alt={data.drawerLogo.Description} />
        </Card.Header>
        <Card.Img src={process.env.REACT_APP_BACKEND_URL + data.drawerLogo.FileName} alt={data.drawerLogo.Description} />
         <Card.ImgOverlay> */}
            <Card.Body>
                <Card.Title>{data.drawerLogo.Description}</Card.Title>
                <img className="drawer_logo_imagee" src={process.env.REACT_APP_BACKEND_URL + data.drawerLogo.FileName} alt={data.drawerLogo.Description} />
            </Card.Body>
        {/* </Card.ImgOverlay> */}
        </Card>
        </div>
    );
}

export default RenderDrawerLogo;
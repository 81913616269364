import React from "react";
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const RenderCompContactNarrow = () => {

  const GET_COMPCONTACT = gql`{
    companyContactDetails (where: {Visible: true, ContactType: "Email"}){
      id
      ContactType
      ContactData
      Visible
    }
  }
`;

const { loading, error, data } = useQuery(GET_COMPCONTACT);
if (loading) return 'Loading...';
if (error) return `Error! ${error.message}`;

return(
    <div className="contact-column-inside">
      
        {
        data.companyContactDetails.map (contact => (
            <span className="compcontact-narrow" key={contact.id}>  {contact.ContactData} </span>
        ))
        }
      
    </div>
);
}

export default RenderCompContactNarrow;